import {getHeaders, handleLogout} from "@/services/auth";

const axios = require('axios');

const API = `${process.env.VUE_APP_BROOM_API_ENDPOINT}`;
const List = API + "listTransaction";
const Refund = API + "apiPaymentezRefund";

export const getList = async (data) => {
  try {
    return await axios.post(List, data,{
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}

export const setRefund = async (id) => {
  try {
    return await axios.post(Refund, id, {
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response.status === 403) {
      handleLogout();
    }
  }
}
