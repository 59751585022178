<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">Transactions</div>
      </md-card-header>
      <md-content>
        <form v-on:submit.prevent="searchId">
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              <md-field>
                <label for="trans">Transaction id</label>
                <md-input id="trans" type="text" v-model="codeTrans" placeholder="Transaction id" required/>
              </md-field>
            </div>
            <div class="col-sm">
              <div class="col-sm" role="group" aria-label="">
                <button type="submit" class="btn btn-default">Search</button>
              </div>
            </div>
          </div>
        </form>

        <div v-if="isVisible">
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              Status:
              <md-chip v-bind:class="getStatus(tableData.status)" class="text-neutral text-capitalize text-center">
                {{ tableData.status }}
              </md-chip>
            </div>
            <div class="col-sm">
              Id Transaction: {{ tableData.idTrans }}
            </div>
          </div>
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              Amount: {{ tableData.amount }}
            </div>
            <div class="col-sm">
              Reference: {{ tableData.dev_reference }}
            </div>
          </div>
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              <base-button type="info" v-bind:class="getDisabled(tableData.status)"
                           @click="showModal(tableData.idTrans)">
                {{ $t('global.txtRefund') }}
              </base-button>
            </div>
          </div>
          <md-dialog :md-active.sync="showDialog">
            <md-dialog-title>{{ $t('global.txtRefund') }}</md-dialog-title>
            <md-content md-dynamic-height class="m-auto">
              <p class="fix_p">{{ $t('global.txtTransaction') }} <b>{{ this.idSend }}</b></p>
              <p class="fix_p">{{ $t('global.txtTransactionMsg') }}</p>
            </md-content>
            <md-dialog-actions>
              <md-button class="md-primary" @click="refundTransaction">Confirm</md-button>
              <md-button class="md-primary" @click="showDialog = false">Close</md-button>
            </md-dialog-actions>
          </md-dialog>
        </div>
      </md-content>
    </md-card>
  </div>
</template>
<style lang="scss" scoped>
.fix_p {
  color: black;
}
</style>

<script>
import BaseTable from "@/components/BaseTable.vue";
import {getList, setRefund} from "@/services/transaction";

export default {
  components: {BaseTable},
  data() {
    return {
      tableData: [],
      showDialog: false,
      idSend: '',
      codeTrans: '',
      isVisible: false
    }
  },
  methods: {
    searchId() {
      let sendData = {id: this.codeTrans}
      if (sendData) {
        getList(sendData).then(res => {
          if (res.data) {
            this.tableData = res.data;
            this.isVisible = true;
          } else {
            this.notify('Not exist Transactions', 'warning');
            this.tableData = [];
            this.isVisible = false;
            this.codeTrans = '';
          }
        });
      }
    },
    refundTransaction() {
      let sendData = {
        id: this.idSend
      };
      if (sendData) {
        setRefund(sendData).then(res => {
          if (res.data === true) {
            this.showDialog = false
            this.notify('Refund success', 'success');
            this.searchId();
          } else {
            this.notify('Refund error', 'warning');
          }
        });
      }
    },
    getStatus(status) {
      switch (status) {
        case 'success':
          return 'btn-success';
        case 'refund':
          return 'btn-warning';
        case 'failure':
          return 'btn-danger';
        default:
          return 'btn-secondary';
      }
    },
    getDisabled(status) {
      switch (status) {
        case 'success':
          return ''
        case 'refund':
          return 'disabled';
        case 'failure':
          return 'disabled';
        default:
          return 'disabled';
      }
    },
    showModal(id) {
      this.idSend = id;
      this.showDialog = true;
    },
    //notifications
    notify(msg, type) {
      this.$notify({
        message: msg,
        type: type,
        icon: "tim-icons icon-bell-55",
        timeout: 5000
      });
    },
  }
}
</script>
